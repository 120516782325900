import { useState, useReducer, useContext } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, ToggleButtonGroup, ToggleButton, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Flex, FlexBetween } from '../../../commonStyles';
import { IAssignment } from '../../../types/assignment';
import AssignmentList from './AssignmentList';
import { purchasersReducer, informationReducer } from './reducers';
import { useAppDispatch } from '../../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import Purchasers from '../../common/forms/Purchasers';
import AssignmentInformation from './AssignmentInformation';
import AssignmentRealtor from './AssignmentRealtor';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { UnitContext } from '../../../context/UnitContext';
import { IPurchaserInfo } from '../../../types/CreateDealForm';
import LoadingLogo from '../../common/LoadingLogo';
import { realtorReducer } from './reducers';
import Owners from './Owners';

const Assignment = () => {
  const storeDispatch = useAppDispatch();
  const { unit } = useContext(UnitContext);
  const project = useSelector(selectProject);
  const [assignments, setAssignments] = useState<IAssignment[]>([]);
  const [assignment, setAssignment] = useState<IAssignment | null>(null);
  const [informationState, informationDispatch] = useReducer(informationReducer, {
    signDate: assignment ? assignment.signDate : null,
    price: assignment ? assignment.price : '',
    current: assignment ? assignment.current : false,
    miscellaneous: assignment ? assignment.miscellaneous : [],
  });
  const [ownersState, ownersDispatch] = useReducer(purchasersReducer, assignment && assignment.owners ? assignment.owners : []);
  const [purchasersState, purchasersDispatch] = useReducer(
    purchasersReducer,
    assignment && assignment.purchasers ? assignment.purchasers : []
  );
  const [ownersRealtorState, ownersRealtorDispatch] = useReducer(
    realtorReducer,
    assignment && assignment.ownersRealtor ? assignment.ownersRealtor : null
  );
  const [purchasersRealtorState, purchasersRealtorDispatch] = useReducer(
    realtorReducer,
    assignment && assignment.purchasersRealtor ? assignment.purchasersRealtor : null
  );
  const [tabValue, setTabValue] = useState<string>(assignment ? 'owners' : 'current');

  const handleTabChange = (e: any, value: string) => {
    if (value !== null) {
      setTabValue(value);
    }
  };

  const { loading } = useQuery(GETASSIGNMENTS, {
    variables: { filter: { project: project._id, unit: unit._id } },
    onCompleted: (data) => {
      let selectedAssignment = data.assignmentMany.find((assignment: IAssignment) => assignment.current);
      if (selectedAssignment) {
        setTabValue('owners');
        setData(selectedAssignment);
      }
      setAssignments(data.assignmentMany);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const setData = (assignment: IAssignment) => {
    ownersDispatch({
      type: 'SWAP',
      payload: assignment.owners,
    });
    purchasersDispatch({
      type: 'SWAP',
      payload: assignment.purchasers,
    });
    ownersRealtorDispatch({
      type: 'ADD',
      payload: assignment.ownersRealtor,
    });
    purchasersRealtorDispatch({
      type: 'ADD',
      payload: assignment.purchasersRealtor,
    });
    informationDispatch({
      type: 'SWAP',
      payload: {
        signDate: assignment.signDate,
        miscellaneous: assignment.miscellaneous,
        price: assignment.price,
        current: assignment.current,
        _id: assignment._id,
      },
    });
    setAssignment(assignment);
  };

  const [createAssignment] = useMutation(CREATEASSIGNMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Assignment Saved!'));
      setAssignment(data.assignmentCreateOne.record);
      setAssignments([assignments, data.assignmentCreateOne.record]);
      setTabValue('owners');
      ownersDispatch({
        type: 'SWAP',
        payload: data.assignmentCreateOne.record.owners,
      });
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('Error Saving Assignment'));
      console.log(err, 'err');
    },
  });

  const [updateAssignment] = useMutation(UPDATEASSIGNMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Assignment Saved!'));
      setAssignment(data.assignmentUpdateById.record);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('Error Saving Assignment'));
      console.log(err, 'err');
    },
  });

  const [archiveAssignment] = useMutation(UPDATEASSIGNMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Assignment Archived!'));
      setAssignment(null);
      setAssignments([...assignments, data.assignmentUpdateById.record]);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('Error Saving Assignment'));
      console.log(err, 'err');
    },
  });

  const handleSave = (id: string, field: string) => {
    if (assignment) {
      let type: any[] = [];
      if (field === 'Purchasers') {
        type = assignment.purchasers.map((purchaser: IPurchaserInfo) => purchaser._id);
      } else if (field === 'Owners') {
        type = assignment.owners.map((purchaser: IPurchaserInfo) => purchaser._id);
      }
      if (!type.includes(id)) {
        updateAssignment({
          variables: {
            _id: assignment._id,
            record: {
              [field.toLocaleLowerCase()]: [...type, id],
            },
          },
        });
      }
    }
  };

  const handleDelete = (id: string, field: string) => {
    if (assignment) {
      if (field === 'purchasers' || field === 'owners') {
        let type: any[] = [];
        if (field === 'purchasers') {
          type = assignment.purchasers.map((purchaser: IPurchaserInfo) => purchaser._id);
        } else if (field === 'owners') {
          type = assignment.owners.map((purchaser: IPurchaserInfo) => purchaser._id);
        }
        updateAssignment({
          variables: {
            _id: assignment._id,
            record: {
              [field]: type.filter((typeId: string) => typeId !== id),
            },
          },
        });
      }
    }
  };

  return (
    <div>
      <FlexBetween>
        <Flex>
          {tabValue === 'previous' ? (
            <ArrowBackIcon
              sx={{ cursor: 'pointer', mr: 1, alignSelf: 'center' }}
              onClick={() => {
                setTabValue('current');
                setAssignment(null);
              }}
            />
          ) : null}
          <h2>Assignment</h2>
        </Flex>
        {assignment ? (
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                archiveAssignment({
                  variables: {
                    _id: assignment._id,
                    record: {
                      current: false,
                    },
                  },
                });
                setTabValue('current');
              }}
            >
              Archive
            </Button>
          </Box>
        ) : null}
      </FlexBetween>
      {!loading ? (
        <>
          <FlexBetween>
            {assignments.length ? (
              <ToggleButtonGroup
                value={tabValue}
                exclusive
                onChange={handleTabChange}
                aria-label="text alignment"
                sx={{
                  '& .MuiToggleButton-root.Mui-selected': {
                    backgroundColor: '#00142a',
                    color: '#fff',
                  },
                  '& .MuiToggleButton-root.Mui-selected:hover': {
                    backgroundColor: '#00142a',
                    color: '#fff',
                  },
                }}
              >
                {assignment ? (
                  <ToggleButton value="owners" aria-label="right aligned">
                    Owners
                  </ToggleButton>
                ) : null}
                {assignment ? (
                  <ToggleButton value="ownersRealtor" aria-label="centered">
                    Owners Realtor
                  </ToggleButton>
                ) : null}
                {assignment ? (
                  <ToggleButton value="purchasers" aria-label="centered">
                    Purchasers
                  </ToggleButton>
                ) : null}
                {assignment ? (
                  <ToggleButton value="purchasersRealtor" aria-label="centered">
                    Purchasers Realtor
                  </ToggleButton>
                ) : null}
                {assignment ? (
                  <ToggleButton value="documents" aria-label="right aligned">
                    Miscellaneous
                  </ToggleButton>
                ) : null}
                <ToggleButton value="previous" aria-label="right aligned">
                  Past Assignments
                </ToggleButton>
              </ToggleButtonGroup>
            ) : null}
          </FlexBetween>
          {!assignment ? (
            <Owners createAssignment={createAssignment} assignment={null} handleSave={handleSave} handleDelete={handleDelete} />
          ) : null}
          {tabValue === 'owners' && assignment ? (
            <Box sx={{ mt: 2 }}>
              <Purchasers
                title="Owners"
                purchasersState={ownersState}
                dispatch={ownersDispatch}
                handleSave={handleSave}
                handleDelete={handleDelete}
              />
            </Box>
          ) : null}
          {tabValue === 'ownersRealtor' && assignment ? (
            <AssignmentRealtor
              title={'Owners Realtor'}
              realtor={ownersRealtorState}
              realtorDispatch={ownersRealtorDispatch}
              handleSave={handleSave}
              assignment={assignment}
            />
          ) : null}
          {tabValue === 'purchasers' && assignment ? (
            <Box sx={{ mt: 2 }}>
              <Purchasers
                title="Purchasers"
                purchasersState={purchasersState}
                dispatch={purchasersDispatch}
                handleSave={handleSave}
                handleDelete={handleDelete}
              />
            </Box>
          ) : null}
          {tabValue === 'purchasersRealtor' && assignment ? (
            <AssignmentRealtor
              title={'Purchasers Realtor'}
              realtor={purchasersRealtorState}
              realtorDispatch={purchasersRealtorDispatch}
              handleSave={handleSave}
              assignment={assignment}
            />
          ) : null}
          {tabValue === 'documents' ? (
            <Box sx={{ mt: 2 }}>
              <AssignmentInformation
                assignment={assignment}
                information={informationState}
                informationDispatch={informationDispatch}
                updateAssignment={updateAssignment}
              />
            </Box>
          ) : null}
          {tabValue === 'previous' ? (
            assignments.length ? (
              <AssignmentList assignments={assignments} setAssignment={setData} />
            ) : (
              <Box sx={{ mt: 2 }}>
                <em>There are no previous assignments</em>
              </Box>
            )
          ) : null}
        </>
      ) : (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <LoadingLogo />
        </Box>
      )}
    </div>
  );
};

const UPDATEASSIGNMENT = gql`
  mutation assignmentUpdateById($_id: MongoID!, $record: UpdateByIdAssignmentInput!) {
    assignmentUpdateById(_id: $_id, record: $record) {
      record {
        _id
        project {
          _id
        }
        unit {
          _id
        }
        purchasers {
          _id
          email
          firstName
          lastName
          corp
          sin
          dob
          identifications {
            _id
            name
            getUrl
          }
          unit
          streetAddress
          city
          province
          country
          postalCode
          occupation
          employer
          directors
          businessNumber
          signingOfficers {
            fullName
            dob
            sin
            primaryPhone
            streetAddress
            email
          }
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          idJurisdiction
          getUrl
          putUrl
        }
        purchasersRealtor {
          _id
          realtor {
            _id
          }
          email
          firstName
          lastName
          brokerage
          streetAddress
          city
          province
          country
          postalCode
          brokeragePhone
          brokerageFax
          directPhone
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        owners {
          _id
          email
          firstName
          lastName
          corp
          sin
          dob
          identifications {
            _id
            name
            getUrl
          }
          unit
          streetAddress
          city
          province
          country
          postalCode
          occupation
          employer
          directors
          businessNumber
          signingOfficers {
            fullName
            dob
            sin
            primaryPhone
            streetAddress
            email
          }
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          idJurisdiction
          getUrl
          putUrl
        }
        ownersRealtor {
          _id
          realtor {
            _id
          }
          email
          firstName
          lastName
          brokerage
          streetAddress
          city
          province
          country
          postalCode
          brokeragePhone
          brokerageFax
          directPhone
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        price
        signDate
        miscellaneous {
          _id
          getUrl
          name
        }
        deposits {
          _id
        }
        current
      }
    }
  }
`;

const CREATEASSIGNMENT = gql`
  mutation assignmentCreateOne($record: CreateOneAssignmentInput!) {
    assignmentCreateOne(record: $record) {
      record {
        _id
        project {
          _id
        }
        unit {
          _id
        }
        purchasers {
          _id
          email
          firstName
          lastName
          corp
          sin
          dob
          identifications {
            _id
            name
            getUrl
          }
          unit
          streetAddress
          city
          province
          country
          postalCode
          occupation
          employer
          directors
          businessNumber
          signingOfficers {
            fullName
            dob
            sin
            primaryPhone
            streetAddress
            email
          }
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          idJurisdiction
          getUrl
          putUrl
        }
        purchasersRealtor {
          _id
          realtor {
            _id
          }
          email
          firstName
          lastName
          brokerage
          streetAddress
          city
          province
          country
          postalCode
          brokeragePhone
          brokerageFax
          directPhone
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        owners {
          _id
          email
          firstName
          lastName
          corp
          sin
          dob
          identifications {
            _id
            name
            getUrl
          }
          unit
          streetAddress
          city
          province
          country
          postalCode
          occupation
          employer
          directors
          businessNumber
          signingOfficers {
            fullName
            dob
            sin
            primaryPhone
            streetAddress
            email
          }
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          idJurisdiction
          getUrl
          putUrl
        }
        ownersRealtor {
          _id
          realtor {
            _id
          }
          email
          firstName
          lastName
          brokerage
          streetAddress
          city
          province
          country
          postalCode
          brokeragePhone
          brokerageFax
          directPhone
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        price
        signDate
        miscellaneous {
          _id
          getUrl
          name
        }
        deposits {
          _id
        }
        current
      }
    }
  }
`;

const GETASSIGNMENTS = gql`
  query assignmentMany($filter: FilterFindManyAssignmentInput) {
    assignmentMany(filter: $filter) {
      _id
      project {
        _id
      }
      unit {
        _id
      }
      purchasers {
        _id
        project {
          _id
        }
        email
        firstName
        lastName
        corp
        sin
        dob
        identifications {
          _id
          name
          getUrl
        }
        unit
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        idJurisdiction
        getUrl
        putUrl
      }
      purchasersRealtor {
        _id
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      owners {
        _id
        project {
          _id
        }
        email
        firstName
        lastName
        corp
        sin
        dob
        identifications {
          _id
          name
          getUrl
        }
        unit
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        idJurisdiction
        getUrl
        putUrl
      }
      ownersRealtor {
        _id
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      price
      signDate
      miscellaneous {
        _id
        getUrl
        name
      }
      deposits {
        _id
      }
      current
    }
  }
`;

export default Assignment;
