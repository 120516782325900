import { useState, useReducer, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Fade } from '@mui/material';

import { UnitContext } from '../../../context/UnitContext';
import { purchasersReducer } from './reducers';
import Purchasers from '../../common/forms/Purchasers';
import { selectProject } from '../../../features/project/projectSlice';
import { IPurchaserInfo } from '../../../types/CreateDealForm';
import { IAssignment } from '../../../types/assignment';

const Owners = (props: ChildProps) => {
  const { assignment, createAssignment, handleSave, handleDelete } = props;
  const project = useSelector(selectProject);
  const { filteredDeal, unit } = useContext(UnitContext);
  const [ownersState, ownersDispatch] = useReducer(purchasersReducer, assignment && assignment.owners ? assignment.owners : []);
  const [type, setType] = useState<boolean | null>(null);

  const handleOwners = (type: boolean) => {
    if (type === false) {
      createAssignment({
        variables: {
          record: {
            project: project._id,
            unit: unit._id,
            owners: [],
            ownersRealtor: null,
            purchasers: [],
            purchasersRealtor: null,
            signDate: null,
            miscellaneous: [],
            deposits: [],
            current: true,
          },
        },
      });
    } else {
      createAssignment({
        variables: {
          record: {
            project: project._id,
            unit: unit._id,
            owners: assignment
              ? assignment.owners.map((purchaser: IPurchaserInfo) => purchaser._id)
              : filteredDeal.purchasers.map((purchaser: IPurchaserInfo) => purchaser._id),
            ownersRealtor: null,
            purchasers: [],
            purchasersRealtor: null,
            signDate: null,
            miscellaneous: [],
            deposits: [],
            current: true,
          },
        },
      });
    }
  };

  return (
    <Box>
      <Fade in={true} timeout={2000}>
        <Box sx={{ textAlign: 'center' }}>
          <strong>Are the preconstruction purchasers still the owners?</strong>
          <Box sx={{ mt: 2 }}>
            <Button sx={{ mr: 2 }} variant="contained" onClick={() => handleOwners(true)} color="success">
              Yes
            </Button>
            <Button variant="contained" onClick={() => handleOwners(false)} color="error">
              No
            </Button>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
};

interface ChildProps {
  assignment?: IAssignment | null;
  createAssignment: any;
  handleSave: any;
  handleDelete: any;
}

export default Owners;
