import React, { useContext, useEffect, useState, memo, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Page, Document, pdfjs } from 'react-pdf';
import { Buffer } from 'buffer';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import MergeButtons from './MergeButtons';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Paper, Pagination, TextField } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import uniqueId from 'lodash.uniqueid';

import { IMergeArray, IMergeCoordinates } from '../../types/merge';
import { MergeContext } from '../../context/MergeContext';
import { mergeItems, signItems } from '../../utils/Constants';
import LoadingWrapper from '../common/LoadingWrapper';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { selectMerges, setMerges } from '../../features/merge/mergeSlice';
import { useAppDispatch } from '../../app/hooks';
import { useCreateActivity } from '../../features/activity/activityHooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { DraggableField } from '../common/dnd/Context';
import MergeInputs from './MergeInputs';
import MergeFieldList from './MergeFieldList';
import 'pdfjs-dist/build/pdf.worker.entry';
import { IUnitHistory } from '../../types/unit';
import { checkSpecialChars } from '../../utils/Functions';
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const MergeCreator = memo(() => {
  const {
    pdfs,
    setRef,
    pageNum,
    setDocumentLoaded,
    selectedSuite,
    totalPages,
    setTotalPages,
    mergeName,
    mergeType,
    mergeFields,
    signFields,
    putUrl,
  } = useContext(MergeContext);
  const storeDispatch = useAppDispatch();
  const createActivity = useCreateActivity();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const widthRef = useRef<any>(null);
  const { mergeid } = useParams();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const merges = useSelector(selectMerges);
  const [mergeFieldPage, setMergeFieldPage] = useState<number>(1);

  const [mergeCoordinates, setMergeCoordinates] = useState<IMergeArray[]>([]);
  const [signCoordinates, setSignCoordinates] = useState<IMergeArray[]>([]);
  const [file, setFile] = useState<any>(null);
  const [search, setSearch] = useState<any>('');

  let context = ref.current?.getContext('2d');
  let rect = ref.current?.getBoundingClientRect();

  let floorplanMerge = project.mergeTemplates.find((merge: any) => merge.name === 'floorplan');
  let floorplanPage = floorplanMerge?.apsTemplates.find((aps: any) => mergeName === aps.name || aps.apsTemplate._id === mergeid);

  const mergeFieldList = [
    {
      name: 'General',
      start: 0,
      end: 51,
    },
    {
      name: 'General 2',
      start: 51,
      end: 96,
    },
    {
      name: 'General 3',
      start: 96,
      end: 141,
    },
    {
      name: 'General 4',
      start: 141,
      end: 159,
    },
    {
      name: 'Realtor',
      start: 159,
      end: 190,
    },
    {
      name: 'Deposits',
      start: 190,
      end: 240,
    },
    {
      name: 'Deposits 2',
      start: 240,
      end: 288,
    },
    {
      name: 'Deposits 3',
      start: 288,
      end: 336,
    },
    {
      name: 'Deposits 4',
      start: 336,
      end: 386,
    },
    {
      name: 'Miscellaneous',
      start: 386,
      end: 433,
    },
    {
      name: 'Miscellaneous 2',
      start: 433,
      end: 480,
    },
    {
      name: 'Miscellaneous 3',
      start: 480,
      end: 486,
    },
    {
      name: 'Occupancy',
      start: 486,
      end: 510,
    },
    {
      name: 'Purchaser Information',
      start: 510,
      end: 536,
    },
    {
      name: 'Deposit/Unit Transfers',
      start: 536,
      end: 575,
    },
    {
      name: 'Deposit/Unit Transfers 2',
      start: 575,
      end: 625,
    },
    {
      name: 'Deposit/Unit Transfers 3',
      start: 625,
      end: 655,
    },
    {
      name: 'Decor',
      start: 655,
      end: 675,
    },
  ];

  const signFieldList = [
    {
      name: 'Sign Fields 1',
      start: 0,
      end: 39,
    },
    {
      name: 'Sign Fields 2',
      start: 39,
      end: 78,
    },
  ];

  useEffect(() => {
    if (mergeid === 'floorplans' && selectedSuite) {
      mergeFloorplansDocuments();
    } else if (pdfs) {
      mergePDFDocuments(pdfs.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfs, selectedSuite, ref]);

  const [createMerge] = useMutation(CREATEMERGETEMPLATE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`${data.mergeTemplateCreateAndUpload.name} has been created!`));
      storeDispatch(setMerges([...merges, data.mergeTemplateCreateAndUpload]));
      createActivity({
        project: project._id,
        user: user._id,
        deal: null,
        title: `Create Template`,
        content: `Template ${data.mergeTemplateCreateAndUpload.name} has been created`,
      });
      navigate(`/${project._id}/dashboard/project-settings`);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateProjectMerge] = useMutation(UPDATEPROJECTMERGE, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [editMerge] = useMutation(UPDATEMERGETEMPLATE, {
    onCompleted: (data) => {
      updateProjectMerge({ variables: { project: project._id, mergeTemplate: data.mergeTemplateUpdateById.record._id } });
      createActivity({
        project: project._id,
        user: user._id,
        deal: null,
        title: `Update Template`,
        content: `Template ${data.mergeTemplateUpdateById.record.name} has been updated`,
      });
      storeDispatch(showSuccessSnackbar('Merge Template has been saved!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateUnit] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Floor Plan Merge Templates has been updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createFloorPlanTemplate] = useMutation(CREATEFLOORPLANTEMPLATE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Floor Plan Merge Templates has been updated!'));
      // Add mergeTemplateId to Unit
      updateUnit({
        variables: {
          _id: selectedSuite._id,
          record: {
            floorPlan: data.mergeTemplateCreateOne.record._id,
            history: [
              ...selectedSuite.history.map((history: IUnitHistory) => {
                return {
                  ...history,
                  user: history.user ? history.user._id : null,
                };
              }),
              {
                type: 'Edit',
                description: `Floorplan Merge Template has been created`,
                timestamp: new Date(),
                user: user._id,
              },
            ],
          },
        },
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const mergeFloorplansDocuments = async () => {
    const url = selectedSuite.getUrl;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const pageCount = await pdfDoc.getPageCount();
    setTotalPages(pageCount);

    await pdfDoc.save();

    await setRef(ref);
    return;
  };

  async function mergePDFDocuments(documents: any) {
    const url = pdfs;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });

    let pageCount = 0;
    try {
      pageCount = await pdfDoc.getPageCount();
    } catch (e) {
      console.log(e, 'e');
      return storeDispatch(showErrorSnackbar('There is an error with the PDF. PDF File may not be compliant'));
    }

    if (signCoordinates.length) {
      let newSignCoordinates = signCoordinates.filter((mergeCoordinate: IMergeArray) => mergeCoordinate.pageNumber! <= pageCount);
      setSignCoordinates(newSignCoordinates);
    }

    if (mergeCoordinates.length) {
      let newMergeCoordinates = mergeCoordinates.filter((mergeCoordinate: IMergeArray) => mergeCoordinate.pageNumber! < pageCount);
      setMergeCoordinates(newMergeCoordinates);
    }

    setTotalPages(pageCount);

    await pdfDoc.save();

    await setRef(ref);
    return;
  }

  const mergeFieldCoordinates = (
    mergeTitle: string,
    mergeSize: number,
    x: number,
    docuSignY: number,
    mergeIndex: number,
    mergeFormat: string,
    wrap: number
  ) => {
    setMergeCoordinates([
      ...mergeCoordinates,
      {
        mergeId: uniqueId(),
        pageNumber: pageNum - 1,
        key: mergeTitle,
        format: mergeFormat,
        fontSize: mergeSize,
        index: mergeIndex,
        x: x,
        y: docuSignY,
        wrap: wrap,
      },
    ]);
  };

  const signFieldCoordinates = (mergeTitle: string, x: number, docuSignY: number, mergeIndex: number) => {
    let docuSignCoord = docuSignY;
    if (floorplanPage) {
      setSignCoordinates([
        ...signCoordinates,
        {
          mergeId: uniqueId(),
          pageNumber: pageNum > floorplanPage.pageNumber ? pageNum : pageNum - 1,
          key: mergeTitle,
          index: mergeIndex,
          x: x,
          y: docuSignCoord,
        },
      ]);
    } else {
      setSignCoordinates([
        ...signCoordinates,
        {
          mergeId: uniqueId(),
          pageNumber: pageNum - 1,
          key: mergeTitle,
          index: mergeIndex,
          x: x,
          y: docuSignCoord,
        },
      ]);
    }
  };

  const getCursorPosition = (
    canvas: any,
    event: any,
    mergeTitle: string,
    mergeType: string,
    mergeIndex: number,
    mergeSize: number = 12,
    format: string,
    moveElement: boolean,
    wrap: number
  ) => {
    if (!canvas) {
      return;
    }
    let rect = canvas.current?.getBoundingClientRect();
    let x = event.activatorEvent.x - rect.left;
    let y = event.activatorEvent.y - rect.top - 1;

    if (!moveElement) {
      if (
        event.delta.x + event.activatorEvent.x > rect.left &&
        event.delta.x + event.activatorEvent.x < rect.right &&
        event.delta.y + event.activatorEvent.y < rect.bottom &&
        event.delta.y + event.activatorEvent.y > rect.top
      ) {
        x = event.delta.x - rect.left + event.activatorEvent.x;

        if (rect.top !== 64) {
          if (rect.top < 0) {
            if (event.delta.y < 0) {
              y = event.activatorEvent.y + Math.abs(rect.top) - Math.abs(event.delta.y);
            } else {
              y = event.activatorEvent.y + Math.abs(rect.top) + Math.abs(event.delta.y);
            }
          } else if (rect.top > 0) {
            if (event.delta.y < 0) {
              y = event.activatorEvent.y - Math.abs(rect.top) - Math.abs(event.delta.y);
            } else {
              y = event.activatorEvent.y - Math.abs(rect.top) + Math.abs(event.delta.y);
            }
          }
        } else if (event.delta.y < 0) {
          y = event.activatorEvent.y - rect.top - Math.abs(event.delta.y);
        } else y = event.activatorEvent.y - rect.top + Math.abs(event.delta.y);
      } else {
        // removed merge field from pdf
        let filteredMergeCoordinates = mergeCoordinates.filter((merge: any) => merge.uniqueId !== event.active.data.current.merge.mergeId);
        setMergeCoordinates(filteredMergeCoordinates);

        let filteredSignCoordinates = signCoordinates.filter((merge: any) => merge.uniqueId !== event.active.data.current.merge.mergeId);
        setSignCoordinates(filteredSignCoordinates);
      }

      if (x > context.canvas.clientWidth || x < 0) {
        return;
      }
      if (y > context.canvas.clientHeight || y < 0) {
        return;
      }

      if (mergeType === 'merge') {
        mergeFieldCoordinates(`[${mergeTitle}]`, mergeSize, x, y, mergeIndex, format, wrap);
      } else {
        signFieldCoordinates(mergeType, x, y, mergeIndex);
      }
    } else {
      // Moving an element again or element created from database
      let x = event.active.data.current.merge.x;
      let y = event.active.data.current.merge.y;

      if (
        event.delta.x + event.activatorEvent.x > rect.left &&
        event.delta.x + event.activatorEvent.x < rect.right &&
        event.delta.y + event.activatorEvent.y < rect.bottom &&
        event.delta.y + event.activatorEvent.y > rect.top
      ) {
        if (event.delta.x > 0) {
          x = event.active.data.current.merge.x + event.delta.x;
        } else if (event.delta.x < 0) {
          x = event.active.data.current.merge.x + event.delta.x;
        }

        if (event.delta.y > 0) {
          y = event.active.data.current.merge.y + event.delta.y;
        } else if (event.delta.y < 0) {
          y = event.active.data.current.merge.y + event.delta.y;
        }

        if (mergeType === 'merge') {
          let newMergeCoordinates = mergeCoordinates.map((mergeCoord: any) => {
            if (mergeCoord.mergeId === event.active.id) {
              return {
                ...mergeCoord,
                x,
                y,
              };
            } else return mergeCoord;
          });
          setMergeCoordinates(newMergeCoordinates);
        } else {
          let newSignCoordinates = signCoordinates.map((mergeCoord: any) => {
            if (mergeCoord.mergeId === event.active.id) {
              return {
                ...mergeCoord,
                x,
                y,
              };
            } else return mergeCoord;
          });
          setSignCoordinates(newSignCoordinates);
        }
      } else {
        let filteredMergeCoordinates = mergeCoordinates.filter((merge: any) => merge.mergeId !== event.active.data.current.merge.mergeId);
        setMergeCoordinates(filteredMergeCoordinates);

        let filteredSignCoordinates = signCoordinates.filter((merge: any) => merge.mergeId !== event.active.data.current.merge.mergeId);
        setSignCoordinates(filteredSignCoordinates);
      }
    }
  };

  const saveMerge = async () => {
    if (mergeid !== 'floorplans' && !mergeName && !mergeType) {
      storeDispatch(showErrorSnackbar(`Title or Template Type is missing`));
      return;
    }

    let floorplanMerge = project.mergeTemplates.find((merge: any) => merge.name === 'floorplan');
    let floorplanPage = floorplanMerge?.apsTemplates.find((aps: any) => mergeName === aps.name);
    let sign = signCoordinates.map((sign: any) => {
      let pageNumber = sign.pageNumber;
      if (sign.pageNumber > floorplanPage!) {
        pageNumber += 1;
      }
      return {
        index: sign.index,
        key: sign.key,
        pageNumber: pageNumber,
        x: sign.x,
        y: sign.y,
      };
    });

    let newMergeCoordinates = mergeCoordinates.map((mergeCoords: any) => {
      return {
        format: mergeCoords.format,
        key: mergeCoords.key.replace(/[[\]']+/g, '').replace(/\s/g, ''),
        index: mergeCoords.index,
        pageNumber: mergeCoords.pageNumber,
        x: mergeCoords.x,
        y: mergeCoords.y,
        fontSize: mergeCoords.fontSize,
        wrap: parseInt(mergeCoords.wrap, 10),
      };
    });

    let mergeObject = {
      project: project._id,
      name: mergeid === 'floorplans' ? selectedSuite.suite : mergeName,
      mergeFields: newMergeCoordinates,
      signFields: sign,
      type: mergeid === 'floorplans' ? 'Floorplan' : mergeType,
      totalPages: totalPages,
    };

    if (checkSpecialChars(mergeName)) {
      return storeDispatch(showErrorSnackbar('Name contains special characters'));
    }

    if (mergeid) {
      if (mergeid === 'floorplans') {
        if (!selectedSuite.floorPlan) {
          createFloorPlanTemplate({ variables: { record: mergeObject } });
        } else {
          await editMerge({ variables: { _id: selectedSuite.floorPlan._id, record: mergeObject } });
        }
      } else {
        if (file) {
          let newMergeFields = mergeObject.mergeFields.filter((merge: any) => merge.pageNumber <= mergeObject.totalPages);
          let newSignFields = mergeObject.signFields.filter((merge: any) => merge.pageNumber <= mergeObject.totalPages);

          let buffer: any;
          if (pdfs.indexOf('application/pdf') !== -1) {
            buffer = Buffer.from(pdfs.replace(/^data:application\/\w+;base64,/, ''), 'base64');
          } else {
            buffer = Buffer.from(pdfs.replace(/^data:image\/\w+;base64,/, ''), 'base64');
          }

          const options = {
            headers: { 'Content-Type': `${file.type}` },
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
          };
          mergeObject = {
            ...mergeObject,
            mergeFields: newMergeFields,
            signFields: newSignFields,
          };

          await axios.put(putUrl, buffer, options);
        }

        await editMerge({ variables: { _id: mergeid, record: mergeObject } });
      }
    } else {
      createMerge({ variables: { record: mergeObject, file: file, recordId: true } });
    }
  };

  function onDocumentLoadSuccess({ numPages: nextNumPages }: { numPages: any }) {
    // Only set coordinates on initial load
    if (mergeid === 'floorplans') {
      setMergeCoordinates(
        mergeFields.map((merge: any) => {
          return { ...merge, mergeId: uniqueId() };
        })
      );
      setSignCoordinates(
        signFields.map((merge: any) => {
          return { ...merge, mergeId: uniqueId() };
        })
      );
    } else {
      if (mergeFields.length && !mergeCoordinates.length) {
        setMergeCoordinates(
          mergeFields.map((merge: any) => {
            let revisedKey = `[${merge.key}]`;
            let wrapNumber = ' '.repeat(parseInt(merge.wrap, 10));
            let trimmedKey = revisedKey.replace(/\s/g, '');
            let string = trimmedKey.substring(0, trimmedKey.length - 1) + wrapNumber + trimmedKey.substring(trimmedKey.length - 1);
            return { ...merge, key: string, mergeId: uniqueId() };
          })
        );
      }

      if (signFields.length && !signCoordinates.length) {
        setSignCoordinates(
          signFields.map((merge: any) => {
            return { ...merge, mergeId: uniqueId() };
          })
        );
      }
    }

    setDocumentLoaded(true);
  }

  const handleDragEnd = (event: any, mergeItem: any) => {
    getCursorPosition(
      ref,
      event,
      event.active.data.current.merge.name ? event.active.data.current.merge.name : event.active.data.current.merge.key,
      event.active.data.current.merge.hasOwnProperty('format') ? 'merge' : 'sign',
      event.active.data.current.merge.index,
      event.active.data.current.merge.fontSize,
      event.active.data.current.merge.format ? event.active.data.current.merge.format : '',
      true,
      mergeItem.wrap
    );
  };

  const getElementDisplay = (pageNumber: number) => {
    if (pageNumber >= floorplanPage?.pageNumber!) {
      if (pageNumber !== pageNum) {
        return 'none';
      } else return 'inline-block';
    } else {
      if (pageNumber !== pageNum - 1) {
        return 'none';
      } else return 'inline-block';
    }
  };

  const handleMergeCoordinates = (value: string, numIndex: number) => {
    let newMergeCoordinates = mergeCoordinates.map((mergeCoords: any, index: number) => {
      if (index === numIndex) {
        return {
          ...mergeCoords,
          wrap: value,
        };
      } else return mergeCoords;
    });
    setMergeCoordinates(newMergeCoordinates);
  };

  const handleBlurWrap = (e: any, numIndex: number) => {
    let newMergeCoordinates = mergeCoordinates.map((mergeCoords: any, index: number) => {
      if (index === numIndex) {
        let wrapNumber = ' '.repeat(parseInt(mergeCoords.wrap, 10));
        let trimmedKey = mergeCoords.key.replace(/\s/g, '');
        let string = trimmedKey.substring(0, trimmedKey.length - 1) + wrapNumber + trimmedKey.substring(trimmedKey.length - 1);
        return {
          ...mergeCoords,
          key: string,
          displayWrap: !mergeCoords.displayWrap,
        };
      } else return mergeCoords;
    });
    setMergeCoordinates(newMergeCoordinates);
  };

  const showWrap = (mergeField: any) => {
    let newMergeCoordinates = mergeCoordinates.map((mergeCoords: any, index: number) => {
      if (mergeCoords.mergeId === mergeField.mergeId) {
        return {
          ...mergeCoords,
          displayWrap: !mergeCoords.displayWrap,
        };
      } else return mergeCoords;
    });
    setMergeCoordinates(newMergeCoordinates);
  };

  const copySignFields = () => {
    if (floorplanPage && floorplanPage.pageNumber && pageNum === floorplanPage.pageNumber + 1) {
      const duplicateSignFields = signCoordinates
        .filter((signCoordinates: IMergeArray) => signCoordinates.pageNumber === pageNum - 2)
        .map((signCoordinates: IMergeArray) => {
          return {
            ...signCoordinates,
            pageNumber: signCoordinates.pageNumber! + 2,
            mergeId: uniqueId(),
          };
        });
      setSignCoordinates([...signCoordinates, ...duplicateSignFields]);
    } else if (floorplanPage && floorplanPage.pageNumber && pageNum <= floorplanPage.pageNumber) {
      const duplicateSignFields = signCoordinates
        .filter((signCoordinates: IMergeArray) => signCoordinates.pageNumber === pageNum - 2)
        .map((signCoordinates: IMergeArray) => {
          return {
            ...signCoordinates,
            pageNumber: signCoordinates.pageNumber! + 1,
            mergeId: uniqueId(),
          };
        });
      setSignCoordinates([...signCoordinates, ...duplicateSignFields]);
    } else {
      const duplicateSignFields = signCoordinates
        .filter((signCoordinates: IMergeArray) => signCoordinates.pageNumber === pageNum - 1)
        .map((signCoordinates: IMergeArray) => {
          return {
            ...signCoordinates,
            pageNumber: signCoordinates.pageNumber! + 1,
            mergeId: uniqueId(),
          };
        });
      setSignCoordinates([...signCoordinates, ...duplicateSignFields]);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignSelf: 'flex-start',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Paper
        elevation={12}
        sx={{
          width: '30%',
          p: 2,
          position: 'sticky',
          top: 0,
          bottom: 0,
          left: 0,
          height: '100%',
          zIndex: 20,
        }}
      >
        <MergeInputs setFile={setFile} />
        <Box sx={{ mt: 1 }}>
          <TextField
            title={'Search Merge'}
            name={'search'}
            fullWidth
            value={search}
            label={'Search Merge'}
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ height: '550px', zIndex: 20 }}>
          {search ? (
            <Box>
              <MergeFieldList
                fieldName={'Search Fields'}
                start={0}
                end={10000}
                getCursorPosition={getCursorPosition}
                mergeItems={[...mergeItems, ...signItems]
                  .filter((merge: any) => merge.name.toLowerCase().includes(search.toLowerCase()))
                  .slice(0, 45)}
              />
            </Box>
          ) : (
            <Box>
              {mergeFieldList.length + 1 === mergeFieldPage ? (
                <MergeFieldList
                  fieldName={signFieldList[0].name}
                  start={signFieldList[0].start}
                  end={signFieldList[0].end}
                  getCursorPosition={getCursorPosition}
                  mergeItems={signItems}
                />
              ) : null}
              {mergeFieldList.length + 2 === mergeFieldPage ? (
                <MergeFieldList
                  fieldName={signFieldList[1].name}
                  start={signFieldList[1].start}
                  end={signFieldList[1].end}
                  getCursorPosition={getCursorPosition}
                  mergeItems={signItems}
                />
              ) : null}
              {mergeFieldPage <= mergeFieldList.length ? (
                <MergeFieldList
                  fieldName={mergeFieldList[mergeFieldPage - 1].name}
                  start={mergeFieldList[mergeFieldPage - 1].start}
                  end={mergeFieldList[mergeFieldPage - 1].end}
                  getCursorPosition={getCursorPosition}
                  mergeItems={mergeItems}
                />
              ) : null}
              <Box sx={{ mt: 2 }}>
                <Pagination
                  sx={{ '& .MuiPagination-ul': { justifyContent: 'center' } }}
                  color="secondary"
                  onChange={(e, page) => setMergeFieldPage(page)}
                  count={mergeFieldList.length + 2}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link style={{ textDecoration: 'none' }} to={`/${project._id}/dashboard/create-merges`}>
            <Button sx={{ mr: 1 }} variant="contained" color="primary" size="small" startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          </Link>
          <Button variant="contained" color="success" size="small" startIcon={<CloudUploadIcon />} onClick={saveMerge}>
            Save
          </Button>
        </Box>
      </Paper>
      <Box
        ref={widthRef}
        sx={{
          zIndex: 1,
          width: '70%',
          height: '100%',
          position: 'relative',
          '& .react-pdf__Page__canvas': {
            margin: '0 auto',
          },
          '& .react-pdf__Page, .react-pdf__Document': {
            height: '100%',
          },
          '& .react-pdf__message--loading': {
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
          },
          '& .react-pdf__message': {
            marginTop: '20px',
            textAlign: 'center',
          },
        }}
      >
        <Document
          style={{ position: 'relative' }}
          error={'An error has occurred or this pdf does not have that many pages.'}
          loading={<LoadingWrapper title="PDF is loading..." modal={false} />}
          file={pdfs}
          key={1}
        >
          {widthRef.current && rect ? (
            <>
              {mergeCoordinates.map((element: any, index: number) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      position: 'absolute',
                      zIndex: '10000',
                      top: element.y - 7,
                      left:
                        rect.width >= 1224 && widthRef?.current?.offsetWidth >= 1224 && mergeid === 'floorplans'
                          ? (widthRef?.current?.offsetWidth - rect.width) / 2 + element.x
                          : rect.width >= 1224 && mergeid === 'floorplans'
                          ? element.x
                          : (widthRef?.current?.offsetWidth - rect.width) / 2 + element.x,
                      fontSize: element.fontSize,
                      display: element.pageNumber !== pageNum - 1 ? 'none' : 'inline-block',
                    }}
                  >
                    {element.displayWrap ? (
                      <Box>
                        <TextField
                          sx={{
                            width: '100px',
                            position: 'absolute',
                            top: '-40px',
                            '& .MuiOutlinedInput-input': { p: 1 },
                            backgroundColor: '#f5f5f5',
                          }}
                          type="number"
                          title={'Wrap Length'}
                          name={'wrap'}
                          onChange={(e) => handleMergeCoordinates(e.target.value, index)}
                          fullWidth
                          value={element.wrap}
                          label={'Wrap Length'}
                          onBlur={() => handleBlurWrap(element, index)}
                        />
                      </Box>
                    ) : null}
                    <DraggableField reset={false} mergeItem={element} handleDragEnd={handleDragEnd} showWrap={showWrap} />
                  </Box>
                );
              })}
              {signCoordinates.map((element: any, index: number) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      position: 'absolute',
                      zIndex: '10000',
                      top: element.y - 7,
                      left:
                        rect.width >= 1224 && widthRef?.current?.offsetWidth >= 1224 && mergeid === 'floorplans'
                          ? (widthRef?.current?.offsetWidth - rect.width) / 2 + element.x
                          : rect.width >= 1224 && mergeid === 'floorplans'
                          ? element.x
                          : (widthRef?.current?.offsetWidth - rect.width) / 2 + element.x,
                      fontSize: element.fontSize,
                      display: getElementDisplay(element.pageNumber),
                    }}
                  >
                    <DraggableField reset={false} mergeItem={element} handleDragEnd={handleDragEnd} showWrap={showWrap} />
                  </Box>
                );
              })}
            </>
          ) : null}
          <Page renderTextLayer={false} canvasRef={ref} scale={1} loading={''} pageNumber={pageNum} onLoadSuccess={onDocumentLoadSuccess} />
        </Document>
        {totalPages > 1 ? <MergeButtons totalPages={totalPages} copySignFields={copySignFields} /> : null}
      </Box>
    </Box>
  );
});

const UPDATEMERGETEMPLATE = gql`
  mutation mergeTemplateUpdateById($_id: MongoID!, $record: UpdateByIdMergeTemplateInput!) {
    mergeTemplateUpdateById(_id: $_id, record: $record) {
      record {
        _id
        project {
          _id
        }
        name
        mergeFields {
          key
          index
          pageNumber
          x
          y
          fontSize
          format
          wrap
        }
        signFields {
          key
          index
          pageNumber
          x
          y
        }
        default
        type
        getUrl
        putUrl
      }
    }
  }
`;

const CREATEFLOORPLANTEMPLATE = gql`
  mutation mergeTemplateCreateOne($record: CreateOneMergeTemplateInput!) {
    mergeTemplateCreateOne(record: $record) {
      record {
        _id
        project {
          _id
        }
        mergeFields {
          key
          index
          pageNumber
          x
          y
          fontSize
          format
          wrap
        }
        signFields {
          key
          index
          pageNumber
          x
          y
        }
        type
        name
      }
    }
  }
`;

const CREATEMERGETEMPLATE = gql`
  mutation mergeTemplateCreateAndUpload($record: CreateOneMergeTemplateInput!, $file: Upload!) {
    mergeTemplateCreateAndUpload(record: $record, file: $file) {
      _id
      project {
        _id
      }
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
      }
      type
      name
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
        floorPlan {
          _id
        }
      }
    }
  }
`;

const UPDATEPROJECTMERGE = gql`
  mutation updateProjectMerge($project: MongoID!, $mergeTemplate: MongoID!) {
    updateProjectMerge(project: $project, mergeTemplate: $mergeTemplate) {
      _id
    }
  }
`;

export default MergeCreator;
