import React from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputAdornment } from '@mui/material';
import { addYears, subDays } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import TextInput from '../common/formControls/TextInput';
import { IDeposits, IProject } from '../../types/project';
import Dropdown from '../common/formControls/Dropdown';
import { amountType, dateType } from '../../utils/Constants';
import { IUnit } from '../../types/unit';

const DepositStructure = (props: ChildProps) => {
  const { type, deposit, handleTextInput, handleDropdownInput, handleDateChange, handleDelete, numIndex, project, unit } = props;

  function displayDateDue(depositInfo: IDeposits, index: number, numIndex: number) {
    switch (depositInfo.dateType) {
      case 'Number of Days Due':
        return (
          <TextInput
            name={'daysDue'}
            type={'number'}
            title={'Days Due'}
            handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'number', numIndex!)}
            value={depositInfo.daysDue}
          />
        );
      case 'Specific Date':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={'Due Date'}
              value={depositInfo.dueDate}
              onChange={(newValue) => handleDateChange(newValue, numIndex, index, 'dueDate')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        );
      case 'Floating Date':
        if (type === 'create') {
          return 'Floating Date';
        } else {
          let date = new Date(addYears(new Date(), 5)).toLocaleDateString();
          if (unit && unit.firmOccupancy) {
            date = unit.firmOccupancy
              ? new Date(unit.firmOccupancy).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          } else if (project && project.firmOccupancy) {
            date = project.firmOccupancy
              ? new Date(project.firmOccupancy).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          }
          return <div>{date}</div>;
        }
      case '180 Days Before Firm Occupancy':
        if (type === 'create') {
          return '180 Days Before Firm Occupancy';
        } else {
          let date = new Date(addYears(new Date(), 5)).toLocaleDateString();
          if (unit && unit.firmOccupancy) {
            date = unit.firmOccupancy
              ? new Date(subDays(new Date(unit.firmOccupancy), 180)).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          } else if (project && project.firmOccupancy) {
            date = project.firmOccupancy
              ? new Date(subDays(new Date(project.firmOccupancy), 180)).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          }
          return <div>{date}</div>;
        }
      case '120 Days Before Firm Occupancy':
        if (type === 'create') {
          return '120 Days Before Firm Occupancy';
        } else {
          let date = new Date(addYears(new Date(), 5)).toLocaleDateString();
          if (unit && unit.firmOccupancy) {
            date = unit.firmOccupancy
              ? new Date(subDays(new Date(unit.firmOccupancy), 120)).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          } else if (project && project.firmOccupancy) {
            date = project.firmOccupancy
              ? new Date(subDays(new Date(project.firmOccupancy), 120)).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          }
          return <div>{date}</div>;
        }
      case '90 Days Before Firm Occupancy':
        if (type === 'create') {
          return '90 Days Before Firm Occupancy';
        } else {
          let date = new Date(addYears(new Date(), 5)).toLocaleDateString();
          if (unit && unit.firmOccupancy) {
            date = unit.firmOccupancy
              ? new Date(subDays(new Date(unit.firmOccupancy), 90)).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          } else if (project && project.firmOccupancy) {
            date = project.firmOccupancy
              ? new Date(subDays(new Date(project.firmOccupancy), 90)).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          }
          return <div>{date}</div>;
        }
      case 'Purchaser Signing Date':
        if (type === 'create') {
          return 'Purchaser Signing Date';
        } else {
          let date = new Date().toLocaleDateString();
          return <div>{date}</div>;
        }
      case '1st of Every Month after 1st Deposit':
        if (type === 'create') {
          return '1st of Every Month after 1st Deposit';
        } else {
          let date = new Date().toLocaleDateString();
          return <div>{date}</div>;
        }
      case '1st of Every Month after 2nd Deposit':
        if (type === 'create') {
          return '1st of Every Month after 2nd Deposit';
        } else {
          let date = new Date().toLocaleDateString();
          return <div>{date}</div>;
        }
      case '1st of Every Month after 3rd Deposit':
        if (type === 'create') {
          return '1st of Every Month after 3rd Deposit';
        } else {
          let date = new Date().toLocaleDateString();
          return <div>{date}</div>;
        }
      case 'Every Month after 1st Deposit':
        if (type === 'create') {
          return 'Every Month after 1st Deposit';
        } else {
          let date = new Date().toLocaleDateString();
          return <div>{date}</div>;
        }
      case 'Every Month after 2nd Deposit':
        if (type === 'create') {
          return 'Every Month after 2nd Deposit';
        } else {
          let date = new Date().toLocaleDateString();
          return <div>{date}</div>;
        }
      case 'Every Month after 3rd Deposit':
        if (type === 'create') {
          return 'Every Month after 3rd Deposit';
        } else {
          let date = new Date().toLocaleDateString();
          return <div>{date}</div>;
        }
      case 'Firm Occupancy':
        if (type === 'create') {
          return 'Firm Occupancy';
        } else {
          let date = new Date().toLocaleDateString();
          if (unit && unit.firmOccupancy) {
            date = unit.firmOccupancy
              ? new Date(unit.firmOccupancy).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          } else if (project && project.firmOccupancy) {
            date = project.firmOccupancy
              ? new Date(project.firmOccupancy).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          }
          return <div>{date}</div>;
        }
      case 'Final Tentative Occupancy':
        if (type === 'create') {
          return 'Final Tentative Occupancy';
        } else {
          let date = new Date().toLocaleDateString();
          if (unit && unit.firmOccupancy) {
            date = unit.finalTentativeOccupancy
              ? new Date(unit.finalTentativeOccupancy).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          } else if (project && project.finalTentativeOccupancy) {
            date = project.finalTentativeOccupancy
              ? new Date(project.finalTentativeOccupancy).toLocaleDateString()
              : new Date(addYears(new Date(), 5)).toLocaleDateString();
          }
          return <div>{date}</div>;
        }
      default:
        return;
    }
  }

  function displayAmount(depositInfo: IDeposits, index: number) {
    switch (depositInfo.type) {
      case 'Fixed':
        return (
          <TextField
            fullWidth
            label={'Amount'}
            title={'Amount'}
            name={'amount'}
            type={'number'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
            value={depositInfo.amount}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        );
      case 'Percentage':
        return (
          <TextField
            fullWidth
            label={'Amount'}
            title={'Amount'}
            name={'amount'}
            type={'number'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
            value={depositInfo.amount}
            InputProps={{
              startAdornment: <InputAdornment position="start">{type === 'deal' ? '$' : '%'}</InputAdornment>,
            }}
          />
        );
      case 'Balance to 1.5%':
        if (type === 'create') {
          return 'Balance to 1.5%';
        } else {
          return (
            <TextField
              fullWidth
              label={'Amount'}
              title={'Amount'}
              name={'amount'}
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
              value={depositInfo.amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          );
        }
      case 'Balance to 2.5%':
        if (type === 'create') {
          return 'Balance to 2.5%';
        } else {
          return (
            <TextField
              fullWidth
              label={'Amount'}
              title={'Amount'}
              name={'amount'}
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
              value={depositInfo.amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          );
        }
      case 'Balance to 3%':
        if (type === 'create') {
          return 'Balance to 3%';
        } else {
          return (
            <TextField
              fullWidth
              label={'Amount'}
              title={'Amount'}
              name={'amount'}
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
              value={depositInfo.amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          );
        }
      case 'Balance to 5%':
        if (type === 'create') {
          return 'Balance to 5%';
        } else {
          return (
            <TextField
              fullWidth
              label={'Amount'}
              title={'Amount'}
              name={'amount'}
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
              value={depositInfo.amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          );
        }
      case 'Balance to 7.5%':
        if (type === 'create') {
          return 'Balance to 7.5%';
        } else {
          return (
            <TextField
              fullWidth
              label={'Amount'}
              title={'Amount'}
              name={'amount'}
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
              value={depositInfo.amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          );
        }
      case 'Balance to 10%':
        if (type === 'create') {
          return 'Balance to 10%';
        } else {
          return (
            <TextField
              fullWidth
              label={'Amount'}
              title={'Amount'}
              name={'amount'}
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
              value={depositInfo.amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          );
        }
      case 'Balance to 15%':
        if (type === 'create') {
          return 'Balance to 15%';
        } else {
          return (
            <TextField
              fullWidth
              label={'Amount'}
              title={'Amount'}
              name={'amount'}
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
              value={depositInfo.amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          );
        }
      case 'Balance to 10% Monthly':
        return (
          <TextField
            fullWidth
            label={'Amount'}
            title={'Amount'}
            name={'amount'}
            type={'number'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
            value={depositInfo.amount}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        );
      case 'Balance to 15% Monthly':
        return (
          <TextField
            fullWidth
            label={'Amount'}
            title={'Amount'}
            name={'amount'}
            type={'number'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
            value={depositInfo.amount}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        );
      case 'Balance to 15% Monthly (36 Months)':
        if (type === 'create') {
          return 'Balance to 15% Monthly (36 Months)';
        } else {
          return (
            <TextField
              fullWidth
              label={'Amount'}
              title={'Amount'}
              name={'amount'}
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextInput(e, index, 'number', numIndex!)}
              value={depositInfo.amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          );
        }

      default:
        return;
    }
  }

  return (
    <div>
      <TableContainer sx={{ mt: 2 }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Deposit Name</strong>
              </TableCell>
              {type === 'create' ? (
                <TableCell align="left">
                  <strong>Amount Type</strong>
                </TableCell>
              ) : null}
              {type === 'deal' ? (
                <TableCell align="left">
                  <strong>Percentage</strong>
                </TableCell>
              ) : null}
              <TableCell align="left">
                <strong>Amount</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Date Type</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Date Due</strong>
              </TableCell>
              {type === 'deal' ? <TableCell align="left"></TableCell> : null}
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deposit.map((depositInfo: IDeposits, index: number) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextInput
                        name={'name'}
                        title={'Deposit Name'}
                        handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'string', numIndex!)}
                        value={depositInfo.name}
                        required={true}
                      />
                    </TableCell>
                    {type === 'create' ? (
                      <TableCell align="left">
                        <Dropdown
                          id={'type'}
                          title={'Type'}
                          menuList={amountType}
                          name={'type'}
                          handleSelect={(e: any) => handleDropdownInput(e, index, numIndex!)}
                          value={depositInfo.type}
                        />
                      </TableCell>
                    ) : null}
                    {type === 'deal' ? (
                      <TableCell align="left">
                        <TextInput
                          name={'percentage'}
                          type={'number'}
                          title={'Percentage'}
                          handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'number', numIndex!)}
                          value={depositInfo.percentage}
                          adornment={'%'}
                        />
                      </TableCell>
                    ) : null}
                    <TableCell align="left">{displayAmount(depositInfo, index)}</TableCell>
                    <TableCell align="left">
                      <Dropdown
                        title={'Date Type'}
                        name={'dateType'}
                        handleSelect={(e: any) => handleDropdownInput(e, index, numIndex!)}
                        menuList={dateType}
                        value={depositInfo.dateType}
                        required={true}
                      />
                    </TableCell>
                    <TableCell align="left">{displayDateDue(depositInfo, index, numIndex!)}</TableCell>
                    <TableCell>
                      <CloseIcon sx={{ cursor: 'pointer' }} color="secondary" onClick={() => handleDelete(index, numIndex!)} />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

interface ChildProps {
  type: string;
  deposit: IDeposits[];
  handleTextInput: (event: any, numIndex: number, type: string, structureIndex: number) => void;
  handleDropdownInput: (event: React.ChangeEvent<{ name: string; value: string }>, numIndex: number, structureIndex: number) => void;
  handleDateChange: any;
  handleDelete: any;
  numIndex?: number;
  project?: IProject;
  unit?: IUnit;
}

export default DepositStructure;
