import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

const Error404 = (props: any) => {
  
  return (
    <Box sx={{ p: 2, textAlign: "center", maxWidth: "800px", margin: "0 auto"}}>
      <Typography sx={{ color: '#00142a', fontSize: '60px' }} variant="h2" component="div" gutterBottom>OOPS!</Typography>
      <h2>You seem to have found an error.</h2>
      <p>Please contact Brian Shew by phone at 416-618-6528 or by email at brians@rdsbrokerage.com if you require assistance.</p>

      {props.location.state.error ? (
        <div>
          <p><strong>Error Found:</strong></p>
          <p>{props.location.state.error}</p>
        </div>
      ) : null}

      {props.location.state.extensions ? 
      <Box>
        <p><strong>Error Type:</strong></p>
        <p>{props.location.state.extensions}</p>
      </Box> 
  
      : null}

      <Link style={{ textDecoration: "none" }} to={`/portal`}>
        <Button variant="contained" color="primary">
          Return to Portal
        </Button>
      </Link>
    </Box>
  );
};

export default Error404;
