import React, { useContext } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { convertAllDates, numToCurrency } from '../../../utils/Functions';
import { IAssignment } from '../../../types/assignment';
import { IPurchaserInfo } from '../../../types/CreateDealForm';

const AssignmentList = (props: ChildProps) => {
  const { assignments, setAssignment } = props;

  return (
    <Grid container sx={{ mt: 2 }} spacing={2}>
      {assignments
        .filter((assignment: IAssignment) => !assignment.current)
        .map((assignment: IAssignment) => {
          return (
            <Grid sx={{ cursor: 'pointer' }} item md={4} xs={12} onClick={() => setAssignment(assignment)}>
              <Paper sx={{ p: 2, height: '100%', backgroundColor: '#def1fd' }} elevation={20}>
                <Typography sx={{ mb: 2 }} variant={'h4'}>
                  <strong>Assignment - {assignment.signDate ? convertAllDates(assignment.signDate, 'PP') : 'No Date'}</strong>
                </Typography>
                <Box>
                  Price: <strong>{numToCurrency.format(assignment.price)}</strong>
                </Box>
                <Box>
                  Assignors:{' '}
                  <strong>{assignment.owners.map((owner: IPurchaserInfo) => `${owner.firstName} ${owner.lastName}`).join(', ')}</strong>
                </Box>
                <Box>
                  Assignees:{' '}
                  <strong>
                    {assignment.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName}`).join(', ')}
                  </strong>
                </Box>
              </Paper>
            </Grid>
          );
        })}
    </Grid>
  );
};

interface ChildProps {
  assignments: IAssignment[];
  setAssignment: any;
}

export default AssignmentList;
