import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import GridOnIcon from '@mui/icons-material/GridOn';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import CommentIcon from '@mui/icons-material/Comment';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import TabletIcon from '@mui/icons-material/Tablet';
import BackupIcon from '@mui/icons-material/Backup';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InsightsIcon from '@mui/icons-material/Insights';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import HouseIcon from '@mui/icons-material/House';
import ConstructionIcon from '@mui/icons-material/Construction';
import TableChartIcon from '@mui/icons-material/TableChart';

import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { menus } from '../../utils/Constants';

function SideMenu(props: ChildProps) {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [access, setAccess] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  const projectAccess = () => {
    let selectedProject = user.projectAccess.find((access: any) => {
      return access.project._id === project._id;
    });
    setAccess(selectedProject.access);
  };

  useEffect(() => {
    projectAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAvataricon(icon: string) {
    switch (icon) {
      case 'AssignmentIcon':
        return <AssignmentIcon />;
      case 'MergeIcon':
        return <MergeTypeIcon />;
      case 'GridOnIcon':
        return <GridOnIcon />;
      case 'InsertChartIcon':
        return <InsertChartIcon />;
      case 'SettingsIcon':
        return <SettingsIcon />;
      case 'ReceiptIcon':
        return <ReceiptIcon />;
      case 'CommentIcon':
        return <CommentIcon />;
      case 'PeopleIcon':
        return <PeopleIcon />;
      case 'DistributionIcon':
        return <LocalShippingIcon />;
      case 'WorksheetIcon':
        return <ListAltIcon />;
      case 'TabletIcon':
        return <TabletIcon />;
      case 'Portal':
        return <ArrowBackIcon />;
      case 'BackupIcon':
        return <BackupIcon />;
      case 'Faqs':
        return <LiveHelpIcon />;
      case 'ImportContacts':
        return <ImportContactsIcon />;
      case 'AssessmentIcon':
        return <AssessmentIcon />;
      case 'SummarizeIcon':
        return <SummarizeIcon />;
      case 'AnalyticsIcon':
        return <AnalyticsIcon />;
      case 'AccountBalanceIcon':
        return <AccountBalanceIcon />;
      case 'InsightsIcon':
        return <InsightsIcon />;
      case 'QueryStatsIcon':
        return <QueryStatsIcon />;
      case 'LeaderboardIcon':
        return <LeaderboardIcon />;
      case 'HouseIcon':
        return <HouseIcon />;
      case 'ConstructionIcon':
        return <ConstructionIcon />;
      case 'TableIcon':
        return <TableChartIcon />;
      default:
        return;
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'calc(100vh - 64px)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          paddingRight: '10px',
        }}
      >
        <CloseIcon
          sx={{
            my: 1,
            cursor: 'pointer',
          }}
          onClick={props.handleClose}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '100%',
        }}
      >
        {menus.map((menu, index) => {
          return access.includes(menu.permission) || !menu.permission ? (
            !menu.subMenu ? (
              <List key={index}>
                <div key={index}>
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#000',
                    }}
                    target={menu.link === 'signing-portal' ? '_blank' : ''}
                    to={`/${project._id}/${menu.link}`}
                    onClick={props.handleClose}
                  >
                    <ListItem
                      button
                      sx={{
                        '&:hover': {
                          backgroundColor: 'rgba(0, 32, 68, 0.8)',
                          color: '#fff',
                        },
                      }}
                    >
                      <ListItemIcon sx={{ color: menu.color }}>{getAvataricon(menu.icon)}</ListItemIcon>
                      <ListItemText primary={menu.title} />
                    </ListItem>
                  </Link>
                </div>
              </List>
            ) : (
              <div key={index}>
                <ListItem
                  button
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 32, 68, 0.8)',
                      color: '#fff',
                    },
                  }}
                  onClick={() => setOpen(!open)}
                >
                  <ListItemIcon sx={{ color: menu.color }}>{getAvataricon(menu.icon)}</ListItemIcon>
                  <ListItemText primary={menu.title} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {menu.subMenuList
                  ?.filter((subMenu: any) => {
                    if (user.type === 'Sales') {
                      return subMenu.title === 'Decor Report';
                    } else {
                      return subMenu;
                    }
                  })
                  .map((subMenu, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <Link
                            style={{
                              textDecoration: 'none',
                              color: '#000',
                            }}
                            to={`/${project._id}/dashboard/${subMenu.link}`}
                            onClick={props.handleClose}
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                sx={{
                                  '&:hover': {
                                    backgroundColor: 'rgba(0, 32, 68, 0.8)',
                                    color: '#fff',
                                  },
                                  pl: 3,
                                }}
                              >
                                <ListItemIcon>{getAvataricon(subMenu.icon)}</ListItemIcon>
                                <ListItemText sx={{ fontWeight: 'bold' }} primary={subMenu.title} />
                              </ListItem>
                            </List>
                          </Link>
                        </Collapse>
                      </React.Fragment>
                    );
                  })}
              </div>
            )
          ) : null;
        })}
        <Box
          sx={{
            marginTop: 'auto',
          }}
        >
          <Link
            style={{
              textDecoration: 'none',
              color: '#000',
            }}
            to={`/portal`}
            onClick={props.handleClose}
          >
            <ListItem
              button
              sx={{
                classes: {
                  '&:hover': {
                    backgroundColor: 'rgba(0, 32, 68, 0.8)',
                    color: '#fff',
                  },
                },
              }}
            >
              <ListItemIcon sx={{ color: '#0d5403' }}>{getAvataricon('Portal')}</ListItemIcon>
              <ListItemText primary={'Back to Portal'} />
            </ListItem>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

interface ChildProps {
  handleClose: () => void;
}

export default SideMenu;
