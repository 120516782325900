import { subYears } from 'date-fns';

export const purchasersReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADDNEW':
      return [
        ...state,
        {
          project: action.payload,
          firstName: '',
          lastName: '',
          corp: false,
          primaryPhone: '',
          email: '',
          dob: subYears(new Date(), 18),
          streetAddress: '',
          city: '',
          province: '',
          country: '',
          postalCode: '',
          idType: '',
          idNumber: '',
          idJurisdiction: '',
          idExpiry: new Date(),
          sin: '',
          occupation: '',
          employer: '',
          purchaserType: '',
          directors: '',
          businessNumber: '',
          signingOfficers: [],
          identifications: [],
        },
      ];
    case 'ADD':
      if (state.length === 1 && !state[0].lastName) {
        return [action.payload];
      } else {
        return [...state, action.payload];
      }
    case 'UPDATEPURCHASER':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return action.payload.data;
        } else return state;
      });
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'UPDATEMULTIPLE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            ...action.payload.values[0],
          };
        } else return state;
      });
    case 'UPDATEADDRESS':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            ...action.payload.value,
          };
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    case 'SWAP':
      return action.payload;
    default:
      throw new Error();
  }
};

export const informationReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPDATEMULTIPLE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            ...action.payload.values[0],
          };
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    case 'SWAP':
      return action.payload;
    default:
      throw new Error();
  }
};

export const realtorReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPDATEMULTIPLE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            ...action.payload.values[0],
          };
        } else return state;
      });
    case 'UPDATEADDRESS':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            ...action.payload.value,
          };
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    case 'DELETECOOP':
      return {
        ...state,
        coopRates: state.coopRates.filter((state: any, index: number) => index !== action.payload.index),
      };
    case 'ADD':
      return action.payload;
    case 'DELETEONE':
      return action.payload;
    default:
      throw new Error();
  }
};
