import { useEffect, useState } from 'react';
import { Box, FormControlLabel, Switch, Grid, Button, Autocomplete, TextField, FormControl } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';

import { IRealtorInfo, IRealtorsArray } from '../../../types/CreateDealForm';
import RealtorForm from '../../common/forms/RealtorForm';
import Coop from '../../createProject/Coop';
import { ICoopRate } from '../../../types/project';
import { FlexBetween, FlexEnd } from '../../../commonStyles';
import { formatPhoneNumber, normalToCamel } from '../../../utils/Functions';
import { useAppDispatch } from '../../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { IAssignment } from '../../../types/assignment';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';

const AssignmentRealtor = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const { realtor, realtorDispatch, title, assignment } = props;
  const [errors, setErrors] = useState<any>({});
  const [hasCoops, setHasCoops] = useState(false);
  const [realtors, setRealtors] = useState<IRealtorInfo[]>([]);

  useQuery<IRealtorsArray>(REALTORS, {
    variables: { filter: { disable: false } },
    onCompleted: (data) => setRealtors(data.realtorMany),
  });

  useEffect(() => {
    if (realtor && realtor.coopRates.length) {
      setHasCoops(true);
    }
  }, [realtor]);

  const [saveNewRealtor] = useMutation(CREATEREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor has been added!'));
    },
    onError: (err) => {
      console.log(err, 'err');
      storeDispatch(showErrorSnackbar(`An Input is Missing`));
    },
  });

  const [updateRealtor] = useMutation(UPDATEREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor has been updated!'));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(`An Input is Missing`));
    },
  });

  const [deleteRealtor] = useMutation(DELETEREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Assignment Saved!'));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('Error Saving Assignment'));
      console.log(err, 'err');
    },
  });

  const handleDelete = (id: string, field: string) => {
    if (field === 'purchasersRealtor' || field === 'ownersRealtor') {
      if (assignment[field] && assignment[field]._id) {
        deleteRealtor({
          variables: {
            project: project._id,
            assignmentRealtor: assignment[field]._id,
            type: field,
          },
        });
      }
      realtorDispatch({
        type: 'DELETEONE',
        payload: null,
      });
    }
  };

  const handleRealtor = (e: any, id: string) => {
    e.preventDefault();
    let { _id, ...newObject } = realtor;
    if (id) {
      if (assignment && assignment.ownersRealtor && normalToCamel(title) === 'ownersRealtor') {
        updateRealtor({ variables: { _id: assignment.ownersRealtor._id, record: { ...newObject, realtor: _id } } });
      } else if (assignment && assignment.purchasersRealtor && normalToCamel(title) === 'purchasersRealtor') {
        updateRealtor({ variables: { _id: assignment.purchasersRealtor._id, record: { ...newObject, realtor: _id } } });
      } else if (assignment && !assignment.ownersRealtor && normalToCamel(title) === 'ownersRealtor') {
        saveNewRealtor({
          variables: {
            record: {
              ...newObject,
              project: project._id,
              assignment: assignment._id,
            },
            type: normalToCamel(title),
            id: id,
          },
        });
      }
    } else if (assignment && !assignment.purchasersRealtor && normalToCamel(title) === 'purchasersRealtor') {
      saveNewRealtor({
        variables: {
          record: {
            ...newObject,
            project: project._id,
            assignment: assignment._id,
          },
          type: normalToCamel(title),
          id: id,
        },
      });
    } else {
      saveNewRealtor({
        variables: {
          record: {
            ...newObject,
            project: project._id,
            assignment: assignment._id,
          },
          type: normalToCamel(title),
        },
      });
    }
  };

  const handleTextInput = async (event: any) => {
    let value = event.target.value;
    if (event.target.name === 'directPhone' || event.target.name === 'brokeragePhone') {
      value = formatPhoneNumber(event.target.value);
    }
    realtorDispatch({ type: 'UPDATE', payload: { field: event.target.name, value: value } });
    if (event.target.value === '') {
      setErrors({
        ...errors,
        [event.target.id]: `${event.target.name} cannot be empty`,
      });
    } else {
      setErrors({
        ...errors,
        [event.target.id]: null,
      });
    }
  };

  const handleCoopInput = (event: any, numIndex: number) => {
    let coopRates = realtor.coopRates.map((coopRate: ICoopRate, index: number) => {
      if (index === numIndex) {
        return {
          ...coopRate,
          [event.target.name]:
            event.target.name === 'days' || event.target.name === 'percentage' || event.target.name === 'fixedAmount'
              ? parseFloat(event.target.value)
              : event.target.value,
        };
      } else return coopRate;
    });
    realtorDispatch({
      type: 'UPDATE',
      payload: {
        field: 'coopRates',
        value: coopRates,
      },
    });
  };

  const handleCoopDate = (newValue: any, numIndex: number) => {
    let coopRates = realtor.coopRates.map((coopRate: ICoopRate, index: number) => {
      if (index === numIndex) {
        return {
          ...coopRate,
          date: newValue,
        };
      } else return coopRate;
    });
    realtorDispatch({
      type: 'UPDATE',
      payload: {
        field: 'coopRates',
        value: coopRates,
      },
    });
  };

  const handleDeleteCoop = (numIndex: number) => {
    if (realtor.coopRates.length === 1) {
      setHasCoops(false);
    }
    realtorDispatch({ type: 'DELETECOOP', payload: { index: numIndex } });
  };

  const handleCoops = () => {
    if (!realtor.coopRates.length) {
      realtorDispatch({
        type: 'UPDATE',
        payload: {
          field: 'coopRates',
          value: [
            {
              type: '',
              days: 0,
              date: new Date(),
              description: '',
              percentage: 0,
              fixedAmount: 0,
            },
          ],
        },
      });
    }
    setHasCoops(!hasCoops);
  };

  const handleSearchInput = (event: any, values: any) => {
    realtorDispatch({
      type: 'ADD',
      payload: {
        ...values,
        coopRates: [],
      },
    });
  };

  return (
    <Box>
      <FormControl
        sx={{
          width: '100%',
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
        }}
      >
        <Autocomplete
          sx={{
            width: '250px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            fontSize: '14px',
            my: 2,
          }}
          id={'search'}
          disableClearable
          freeSolo={false}
          options={realtors}
          getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
          onChange={handleSearchInput}
          renderInput={(params) => (
            <TextField required={false} {...params} size="small" label={'Search By Name or Email'} margin="normal" />
          )}
        />
      </FormControl>
      {realtor ? (
        <>
          <h3 style={{ margin: 0 }}>
            <strong>{title}</strong>
          </h3>
          <Box sx={{ mt: 2 }}>
            <form onSubmit={(e) => handleRealtor(e, realtor ? realtor._id : '')}>
              <RealtorForm realtorInfo={realtor} handleTextInput={handleTextInput} />
              <>
                <FlexBetween sx={{ my: 2 }}>
                  <FormControlLabel
                    sx={{ mt: 1 }}
                    onClick={(e) => e.stopPropagation()}
                    control={<Switch checked={hasCoops} onChange={(e: any) => handleCoops()} />}
                    label={'Coops'}
                  />
                  {hasCoops ? (
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          realtorDispatch({
                            type: 'UPDATE',
                            payload: {
                              field: 'coopRates',
                              value: [
                                ...realtor.coopRates,
                                {
                                  type: '',
                                  days: 0,
                                  date: new Date(),
                                  description: '',
                                  percentage: 0,
                                  fixedAmount: 0,
                                },
                              ],
                            },
                          })
                        }
                      >
                        Add Coop Rate
                      </Button>
                    </Box>
                  ) : null}
                </FlexBetween>
                {hasCoops ? (
                  <Box>
                    <Grid container spacing={2}>
                      {realtor.coopRates.map((coopRate: ICoopRate, index: number) => {
                        return (
                          <Coop
                            key={index}
                            index={index}
                            structureIndex={0}
                            coopRate={coopRate}
                            handleCoopType={handleCoopInput}
                            handleCoopInput={handleCoopInput}
                            handleDeleteCoop={handleDeleteCoop}
                            handleCoopDate={handleCoopDate}
                          />
                        );
                      })}
                    </Grid>
                  </Box>
                ) : null}
              </>
              <FlexEnd>
                <Button variant="contained" color="error" onClick={() => handleDelete(realtor ? realtor._id : '', normalToCamel(title))}>
                  Delete Realtor
                </Button>
                <Button variant="contained" color="success" type="submit" sx={{ ml: 1 }}>
                  Save Realtor
                </Button>
              </FlexEnd>
            </form>
          </Box>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            realtorDispatch({
              type: 'ADD',
              payload: {
                _id: '',
                email: '',
                firstName: '',
                lastName: '',
                brokerage: '',
                streetAddress: '',
                city: '',
                province: '',
                country: '',
                postalCode: '',
                brokeragePhone: '',
                brokerageFax: '',
                directPhone: '',
                coopRates: [],
              },
            })
          }
        >
          Add New Realtor
        </Button>
      )}
    </Box>
  );
};

interface ChildProps {
  realtor: any;
  realtorDispatch: any;
  title: string;
  handleSave: any;
  assignment: IAssignment;
}

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      brokerage
      streetAddress
      city
      province
      country
      postalCode
      brokeragePhone
      brokerageFax
      directPhone
    }
  }
`;

const CREATEREALTOR = gql`
  mutation assignmentRealtorCreateOne($record: CreateOneAssignmentRealtorInput, $type: String!, $id: MongoID) {
    assignmentRealtorCreateOne(record: $record, type: $type, id: $id) {
      _id
    }
  }
`;

const UPDATEREALTOR = gql`
  mutation assignmentRealtorUpdateById($_id: MongoID!, $record: UpdateByIdAssignmentRealtorInput!) {
    assignmentRealtorUpdateById(_id: $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

const DELETEREALTOR = gql`
  mutation assignmentRealtorDeleteOne($project: MongoID!, $assignmentRealtor: MongoID!, $type: String!) {
    assignmentRealtorDeleteOne(project: $project, assignmentRealtor: $assignmentRealtor, type: $type)
  }
`;

export default AssignmentRealtor;
