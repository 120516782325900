import { useState, useReducer } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Divider } from '@mui/material';

import DepositStructure from '../createDeal/DepositStructure';
import { IDepositStructures, IDeposits } from '../../types/project';
import TextInput from '../common/formControls/TextInput';
import SwitchButton from '../common/formControls/SwitchButton';
import { selectProject, setProject, setDepositStructures as setProjectDepositStructures } from '../../features/project/projectSlice';
import { depositsReducer } from '../../features/project/projectHooks';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';

const DepositInfo = () => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();

  const [depositStructures, setDepositStructures] = useState(project?.depositStructures);
  const [depositsState, depositsDispatch] = useReducer(depositsReducer, depositStructures);

  const [updateProject] = useMutation(UPDATEPROJECT, {
    onCompleted: (data) => {
      storeDispatch(setProject(data.projectUpdateById.record));
      storeDispatch(showSuccessSnackbar('Deposits has been updated!'));
      storeDispatch(setProjectDepositStructures(data.projectUpdateById.record.depositStructures));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const addDepositStructure = () => {
    depositsDispatch({ type: 'ADD' });
  };

  const handleDefaultChange = async (numIndex: number) => {
    depositsDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'default', value: !depositsState[numIndex].default } });
  };

  const handleDepositTitleChange = async (event: any, numIndex: number) => {
    depositsDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'name', value: event.target.value } });
  };

  const addDeposit = (numIndex: number) => {
    if (depositStructures.length) {
      depositsDispatch({
        type: 'UPDATE',
        payload: {
          index: numIndex,
          field: 'deposits',
          value: [
            ...depositsState[numIndex].deposits,
            {
              name:
                depositsState[numIndex].deposits.length > 0
                  ? depositStructures[0].deposits[depositsState[numIndex].deposits.length]
                    ? depositStructures[0].deposits[depositsState[numIndex].deposits.length].name
                    : ''
                  : '', // 1st Deposit, 2nd Deposit etc
              type: '', // Fixed or Percent
              amount: 0, // 5,000 or 2.5%
              dateType: '',
              daysDue: 0, // 30 days
              dueDate: new Date(), // Specific Date
            },
          ],
        },
      });
    } else {
      depositsDispatch({
        type: 'UPDATE',
        payload: {
          index: numIndex,
          field: 'deposits',
          value: [
            ...depositsState[numIndex].deposits,
            {
              name: '',
              type: '', // Fixed or Percent
              amount: 0, // 5,000 or 2.5%
              dateType: '',
              daysDue: 0, // 30 days
              dueDate: new Date(), // Specific Date
            },
          ],
        },
      });
    }
  };

  const handleTextInput = (event: any, depositIndex: number, type: string, structureIndex: number) => {
    let depositArray = depositsState[structureIndex].deposits.map((deposit: IDeposits, index: number) => {
      let value = event.target.value;
      if (type === 'number') {
        value = parseFloat(value);
      }
      if (depositIndex === index) {
        return { ...deposit, [event.target.name]: value };
      } else return deposit;
    });

    depositsDispatch({
      type: 'UPDATE',
      payload: {
        index: structureIndex,
        field: 'deposits',
        value: depositArray,
      },
    });
  };

  const handleDelete = (numberIndex: number, structureIndex: number) => {
    let depositArray = depositsState[structureIndex].deposits.filter((deposit: IDeposits, index: number) => index !== numberIndex);

    depositsDispatch({
      type: 'UPDATE',
      payload: {
        index: structureIndex,
        field: 'deposits',
        value: depositArray,
      },
    });
  };

  const handleDropdownInput = (event: React.ChangeEvent<{ name: string; value: string }>, numberIndex: number, structureIndex: number) => {
    let depositArray = depositsState[structureIndex].deposits.map((deposit: IDeposits, index: number) => {
      if (numberIndex === index) {
        return { ...deposit, [event.target.name]: event.target.value };
      } else return deposit;
    });

    depositsDispatch({
      type: 'UPDATE',
      payload: {
        index: structureIndex,
        field: 'deposits',
        value: depositArray,
      },
    });
  };

  const handleDateChange = (newValue: any, structureIndex: number, numberIndex: number) => {
    let depositArray = depositsState[structureIndex].deposits.map((deposit: IDeposits, index: number) => {
      if (numberIndex === index) {
        return { ...deposit, dueDate: newValue };
      } else return deposit;
    });

    depositsDispatch({
      type: 'UPDATE',
      payload: {
        index: structureIndex,
        field: 'deposits',
        value: depositArray,
      },
    });
  };

  const saveDeposits = (event: any) => {
    event.preventDefault();
    updateProject({
      variables: {
        _id: project?._id,
        record: {
          depositStructures: depositsState,
        },
      },
    });
  };

  const copyDeposit = (index: number) => {
    depositsDispatch({
      type: 'COPY',
      payload: {
        ...depositsState[index],
        name: `${depositsState[index].name} COPY`,
      },
    });
  };

  const deleteDeposit = (index: number) => {
    depositsDispatch({
      type: 'DELETE',
      payload: {
        index: index,
      },
    });
  };

  return (
    <form onSubmit={saveDeposits}>
      <h2>Deposit Structures</h2>
      {depositsState.map((depositStructure: IDepositStructures, index: number) => {
        return (
          <Box key={index} sx={{ py: 1 }}>
            <Box
              sx={{
                border: '1px solid #000',
                padding: '30px 20px',
                position: 'relative',
                borderRadius: '8px',
                marginBottom: '20px',
              }}
            >
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextInput
                      id={'name'}
                      title={'Deposit Structure Name'}
                      handleTextInput={(e: HTMLInputElement) => handleDepositTitleChange(e, index)}
                      value={depositStructure.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <SwitchButton
                      state={depositStructure.default}
                      handleChange={() => handleDefaultChange(index)}
                      title={'Default Deposit Structure'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'right' }}>
                    <Button variant="contained" color="primary" onClick={() => copyDeposit(index)} sx={{ mr: 2 }}>
                      Copy
                    </Button>
                    <Button variant="contained" color="error" onClick={() => deleteDeposit(index)}>
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <DepositStructure
                type={'create'}
                deposit={depositStructure.deposits}
                handleTextInput={handleTextInput}
                handleDropdownInput={handleDropdownInput}
                handleDateChange={handleDateChange}
                handleDelete={handleDelete}
                numIndex={index}
              />
              <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={() => addDeposit(index)}>
                Add Deposit
              </Button>
            </Box>
            <Divider sx={{ mt: 2 }} />
          </Box>
        );
      })}
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={() => addDepositStructure()}>
          Add Different Deposit Type
        </Button>
      </Box>
      <Box sx={{ my: 2 }}>
        <Button type="submit" variant="contained" color="success">
          Save Deposits
        </Button>
      </Box>
    </form>
  );
};

const UPDATEPROJECT = gql`
  mutation projectUpdateById($_id: MongoID!, $record: UpdateByIdProjectInput!) {
    projectUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        developerName
        email
        trackingPhone
        addresses {
          streetAddress
          city
          province
          postalCode
        }
        options {
          name
          totalAvailable
          price
          allowedUnits
          type
        }
        depositStructures {
          name
          deposits {
            name
            type
            amount
            daysDue
            dateType
            dueDate
          }
          default
        }
        mergeTemplates {
          name
          mergeTemplate {
            name
            _id
          }
          apsTemplates {
            name
            apsTemplate {
              _id
              name
            }
            pageNumber
            attachToAps
          }
        }
        status {
          name
          code
          color
        }
        executors {
          name
          email
        }
        mortgageLimit
        mortgageMinDay
        hideOccupancy
        defaultZeroValue
        lawyer
        tagLine
        salesOffice
        adjustments {
          name
          type
        }
        commissionIncludeOptions
        emailTemplates {
          _id
          name
          subject
          html
        }
        logoGetUrl
        logoPutUrl
        imageGetUrl
        imagePutUrl
        coopStructures {
          name
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        firstTentativeOccupancy
        finalTentativeOccupancy
        firmOccupancy
        outsideOccupancy
        active
        decorModels {
          modelType
          allowed
        }
        portal {
          primaryColor
        }
        combinedProjects {
          _id
          name
        }
      }
    }
  }
`;

export default DepositInfo;
